.ui.tiny.modal.transition.visible.active.myModalClass {
  padding: 1rem 2rem;
}

.myModalClass .header {
  padding-bottom: 1.5rem !important;
  border: 0 !important;
}

.ui.label.labelColor {
  display: block;
  margin: 0 0 0.5rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  background: transparent;
  font-size: 14px;
  padding: 0;
}

button.ui.red.button.myChangeNoButton {
  width: 100%;
  margin-top: 20px;
  background-color: #ff0037 !important;
  color: #fff !important;
  border: 1px solid #ff0037 !important;
  margin-bottom: 20px !important;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  /* margin: 0 .25em 0 0; */
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 1px transparent inset,
    0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.1s ease, background-color 0.1s ease,
    color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  will-change: "";
  -webkit-tap-highlight-color: transparent;
}

.inputClass {
  height: 38px;
  width: 100%;
}

.bodytype-box .custom-radio label::before {
  content: "";
  margin: 0px auto;
  display: block;
  height: 80px !important;
  width: 30px !important;
  border: none !important;
  background-repeat: no-repeat !important;
  position: relative;
  top: 0px;
}

.ui.search.w-100.wd-input.borderNone input {
  border: none;
}
.ui.search.w-100.wd-input.borderNone div {
  width: 100%;
}

.rbt.clearfix.open.myclass {
  height: 40px;
  border: solid 1px rgba(151, 151, 151, 0.2);
}

.ui .calendar input {
  border-radius: 2px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03) !important;
  border: solid 1px rgba(151, 151, 151, 0.2) !important;
}
.country-list {
  width: 100% !important;
}

.scrolling.dimmable.dimmed {
  overflow: hidden !important;
}
